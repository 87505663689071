.shop-product {
    margin: 1em;

    display: flex;
    flex-direction: column;

    width: 300px;
    height: 300px;

    border: 2px solid #EDEDED;
    border-radius: 1em;

    color: black;
    text-decoration: none;

    transition: all 0.5s;

    box-shadow: 2px 2px 29px -15px rgba(0,0,0,0.75);
}

.shop-product:hover {
    width: 305px;
    height: 305px;

    box-shadow: 3px 3px 29px -5px rgba(0,0,0,0.75);
}

.shop-product h1{
    margin-left: 1em;
    font-family: "Bebas Neue", sans-serif;
    font-size: 1.5em;
}

.shop-product h2 {
    margin-left: 1.5em;
    margin-top: auto;

    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1em;
}

.shop-product img {
    height: 150px;
    width: 150px;

    margin: 1em auto;
}