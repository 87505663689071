.navbar {
    margin: 0;
    padding: 20px 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4em;

    border-bottom: 2px solid #EDEDED
}

.navbar h1 {
    margin: 0 0 0 17%;
}

.navbar ul {
    margin: 0 40% 0 auto;

    display: flex;
    flex-direction: row;
}

.navbar li {
    margin: 0 20px;
    display: inline-block;

    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.navbar li a {
    color: black;
    text-decoration: none;
}

@media only screen and (max-width: 1000px){
    .navbar {
        font-size: 1em;

        padding: 0.5em;
    }
    
    .navbar h1{
        margin: 0 auto 0 0.5em;
    }

    .navbar ul {
        margin-right: 1em;
    }
}