.download-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    
    margin-top: 3em;

}

.download-content img {
    height: auto;
    width: auto;

    max-width: 100%;
}

.download-content .product-details {
    display: flex;
    flex-direction: column;

    width: 35%;

    margin-left: 2em;

    justify-content: center;
}

.download-content .product-details h2 {
    margin: 0;

    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-size: 2em;
}

.download-content .product-details h3 {
    padding-bottom: 0.5em;
    margin-top: 0.25em;

    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1.5em;

    border-bottom: 1px solid #EDEDED;
}

.download-content .product-details p {
    margin-bottom: 4em;

    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1em;
    text-align: left;
}

.download-content button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2em;
    width: 50%;

    margin: 0 auto;
    padding: 0.5em 4em;
    border: none;  

    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-size: 1.5em;
}

.download-content button:hover {
    cursor: pointer;
    background-color: #ABABAB
}

.download-content input {
    display: flex;
    justify-content: flex-start;
    height: 2em;
    width: 50%;

    margin: 1em auto;

    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1em;
}

@media only screen and (max-width: 1000px){
    .download-content {
        flex-direction: column;

        margin: 1em 1em;

        align-items: center;
    }

    .download-content .product-details {
        margin: 1em 0 0 0;
        width: 90%;
    }

    .download-content .product-details p {
        margin: 0 0 2em 0 ;
    }

    .download-content button {
        margin-top:
    }
}
