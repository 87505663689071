.thank-you-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 60%;
    margin: 0 auto;
}

.thank-you-container h1 {
    font-family: "Bebas Neue", sans-serif;
    font-size: 4rem;
}

.thank-you-container p {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;

    text-align: center;
}