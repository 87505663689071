@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.page-content {
  background-color: white;
}

html, body, #root, .root, .page-content {
  height: 100%;
}