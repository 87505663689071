footer {
    display: flex;
    flex-direction: column;
    background-color: #EDEDED;
    margin-top: 4em;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    color: #787878;
}
.social-icons {
    margin-top: 2em;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    list-style: none;
}
.social-icons svg {
    width: 24px;
    height: 24px;
}
.social-icons a:visited {
    color: black;
}
li {
    padding-right: 1em;
}